export const AUTH_SECTION_ID = 'auth';

// Mapping between "pageId" (name of template file) to internal page id used in different places
export const PAGE_ID_MAPPING = {
  'login.ftl': 'login',
  'register.ftl': 'register',
  'login-verify-email.ftl': 'email_activation',
  'login-reset-password.ftl': 'reset_password',
  'login-update-profile.ftl': 'profile_update', // Obsolete
  'login-update-password.ftl': 'password_update', // Obsolete
  'login-page-expired.ftl': 'login_page_expired',
  'update-email.ftl': 'email_update', // Obsolete
  'error.ftl': 'error',
  'info.ftl': 'info',
} as const;

export const ANALYTICS_PAGE_MAPPER = {
  '/realms/:realm/login-actions/authenticate': `${AUTH_SECTION_ID}_login`,
  '/realms/:realm/login-actions/registration': `${AUTH_SECTION_ID}_register`,
  '/realms/:realm/login-actions/required-action': `${AUTH_SECTION_ID}_email_activation`,
  '/realms/:realm/login-actions/reset-credentials': `${AUTH_SECTION_ID}_reset_password`,
} as const;
