import type { AnalyticsBrowser } from '@segment/analytics-next';
import * as Sentry from '@sentry/react';
import postHog from 'posthog-js';
import { PostHogProvider, useActiveFeatureFlags } from 'posthog-js/react';
import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useAnalyticsInitialization } from '../analytics/hooks';

const MAXIMUM_POSTPONE_TIME_MS = 1000;
const MAXIMUM_WAIT_FOR_EXPERIMENTS = 1000;

type PostponeInitializationFunc = (promise: Promise<void>) => void;

type FeatureFlagName =
  | 'vercel_auto_delete_obsolete_branches_toggle_switch'
  | 'github_integration'
  | 'github_integration_v2'
  | 'github_integration_branches'
  | 'outerbase_integration'
  | 'logical_replication_enroll'
  | 'time_travel_mode_sql_editor'
  | 'growth_upgrade_plan_modal'
  | 'growth_globe_as_no_projects_placeholder_for_paid_users'
  | 'growth_upgrade_flow_deeplink'
  | 'growth_usage_widget_with_limits'
  | 'growth_show_data_transfer_limit'
  | 'growth_dashboard_branches_widget_cu_edit'
  | 'growth_referral_codes'
  | 'growth_enable_autoscaling_modal'
  | 'growth_edit_endpoint_promo'
  | 'serverless_driver_sql_editor'
  | 'protected_branches'
  | 'branch_page_tabs'
  | 'create_orgs'
  | 'sharding_enrollment'
  | 'orgs_downgrade_or_upgrade'
  | 'growth_quickstart'
  | 'early_access_ui'
  | 'reorder_sidebar'
  | 'disable_button_on_operations'
  | 'monitoring_lfc_graph'
  | 'growth_monitoring_alerts'
  | 'billing_info';

type ExperimentName = never;

interface PostHogInfo {
  isEnabled: boolean;
  isInitialized: boolean;
  isFeatureFlagsLoaded: boolean;
  bootstrappedFeatureFlags: Record<string, boolean | string | undefined>;
  setInitialized: () => void;
}

const postHogInfoContext = createContext<PostHogInfo>({
  isEnabled: false,
  isInitialized: false,
  isFeatureFlagsLoaded: false,
  bootstrappedFeatureFlags: {},
  setInitialized: () => {},
});

async function timeout<T>(
  timeoutMs: number,
  error: Error,
  targetPromise: Promise<T>,
): Promise<T> {
  return Promise.race([
    targetPromise,
    new Promise<T>((resolve, reject) => {
      setTimeout(() => reject(error), timeoutMs);
    }),
  ]);
}

interface PostHogConfig {
  apiKey: string;
  flags: Record<string, string | boolean>;
}

interface PostHogCustomProviderProps {
  analytics?: AnalyticsBrowser;
  postponeInitialization?: PostponeInitializationFunc;
  postHogConfig?: PostHogConfig;
  userId?: string;
  children: ReactNode;
}

const PostHogCustomProvider = ({
  analytics,
  postponeInitialization,
  postHogConfig,
  children,
  userId,
}: PostHogCustomProviderProps) => {
  const isAlreadyInitRef = useRef(false);
  const resolveRef = useRef<(() => void) | undefined>();

  const { setInitialized } = useContext(postHogInfoContext);

  useLayoutEffect(() => {
    if (!isAlreadyInitRef.current && postponeInitialization) {
      postponeInitialization(
        new Promise<void>((resolve) => {
          resolveRef.current = resolve;
          window.setTimeout(resolve, MAXIMUM_POSTPONE_TIME_MS);
        }),
      );
      isAlreadyInitRef.current = true;
    }
  }, [postponeInitialization]);

  useEffect(() => {
    async function init() {
      if (!postHogConfig?.apiKey) {
        resolveRef.current?.();
        return;
      }

      if (analytics) {
        try {
          await timeout(
            2000,
            new Error('Loading of analytics took more than 2 sec'),
            analytics.ready(),
          );
        } catch (error) {
          Sentry.captureException(error);
        }
      }

      try {
        postHog.init(postHogConfig.apiKey, {
          api_host: 'https://app.posthog.com',
          segment: analytics?.instance,
          capture_pageview: false,
          autocapture: false,
          // NOTE: Posthog by default stores some information in a cookie.
          // This gets problematic as cookie value grows in size. Our nginx proxy
          // will reject requests with large cookie values. So if you need
          // persistence than make sure it is not stored in a cookie!
          disable_persistence: true,
          persistence: 'memory',
          ip: false,
          // This stops posthog from sending a request to evaluate flags in the cloud.
          advanced_disable_decide: true,
          // Bootstrapping means that no requests are sent from the client side
          // when initializing posthog SDK. Backend forwards all feature flags
          // to the UI.
          bootstrap: {
            distinctID: userId,
            isIdentifiedID: Boolean(userId),
            featureFlags: postHogConfig?.flags,
          },
          loaded: () => {
            setInitialized();
            resolveRef.current?.();

            // Only for debug via DevTools purposes
            if (window.location.host !== 'console.neon.tech') {
              (window as any).posthog = postHog;
            }
          },
          session_recording: {
            maskAllInputs: true,
            maskTextSelector: '*',
          },
        });
      } catch (error) {
        resolveRef.current?.();
        // eslint-disable-next-line no-console
        console.error(error);
        Sentry.captureException(error);
      }
    }

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics, postHogConfig, userId]);

  return <PostHogProvider>{children}</PostHogProvider>;
};

const PostHogInfoProvider = postHogInfoContext.Provider;

interface ExperimentsProviderProps {
  isEnabled: boolean;
  postHogConfig?: PostHogConfig;
  children: ReactNode;
  userId?: string;
}

export const ExperimentsProvider = ({
  isEnabled,
  postHogConfig,
  children,
  userId,
}: ExperimentsProviderProps) => {
  const { analytics, postponeInitialization } = useAnalyticsInitialization();

  const [isPostHogEnabled] = useState(
    Boolean(isEnabled && postHogConfig?.apiKey),
  );

  const [isLoaded, setIsLoaded] = useState(!isPostHogEnabled);

  const [isInitialized, setIsInitialized] = useState(false);

  const postHogInfo = useMemo<PostHogInfo>(
    () => ({
      isEnabled: isPostHogEnabled,
      isInitialized,
      isFeatureFlagsLoaded: isLoaded,
      bootstrappedFeatureFlags: postHogConfig?.flags ?? {},
      setInitialized: () => setIsInitialized(true),
    }),
    [isInitialized, isPostHogEnabled, isLoaded, postHogConfig],
  );

  const activeFlags = useActiveFeatureFlags();
  const isActiveFlagsLoaded = Boolean(activeFlags);

  if (isActiveFlagsLoaded && !isLoaded) {
    setIsLoaded(true);
  }

  useEffect(() => {
    const timeoutId = window.setTimeout(() => {
      setIsLoaded(true);
    }, MAXIMUM_WAIT_FOR_EXPERIMENTS);

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, []);

  return (
    <PostHogInfoProvider value={postHogInfo}>
      <PostHogCustomProvider
        postHogConfig={isPostHogEnabled ? postHogConfig : undefined}
        analytics={analytics}
        postponeInitialization={postponeInitialization}
        userId={userId}
      >
        {children}
      </PostHogCustomProvider>
    </PostHogInfoProvider>
  );
};

export function useFeatureFlag(
  featureFlagName: FeatureFlagName | ExperimentName,
): boolean | string | undefined {
  const { bootstrappedFeatureFlags } = useContext(postHogInfoContext);
  return bootstrappedFeatureFlags[featureFlagName];
}

export function useFeatureFlagEnabled(
  featureFlagName: FeatureFlagName,
): boolean {
  return Boolean(useFeatureFlag(featureFlagName));
}

// This hook allows to get activated variant of a selected by name experiment.
// "undefined" will be returned while feature flags are loading.
// The full documentation can be found here: docs/frontend/experiments.md
export function useExperiment(
  experimentName: ExperimentName,
): undefined | string {
  const { isFeatureFlagsLoaded } = useContext(postHogInfoContext);
  const featureFlagValue = useFeatureFlag(experimentName);

  if (featureFlagValue === undefined) {
    if (isFeatureFlagsLoaded) {
      return 'control';
    }
    return undefined;
  }

  // Experiments works only with strings
  if (typeof featureFlagValue !== 'string') {
    return 'control';
  }

  return featureFlagValue;
}
